<template>
	<div>
		<b-card-countdown
			stage="absolución de consultas"
			:deadline="deadline"
			:start-date="startDate"
			:stage-closed="stageClosed"
			@time-finished="handleTimeFinished"
			@not-yet-started="handleNotYetStarted"
		/>

		<!-- Filters -->
		<!-- 	<bidders-list-filters
			:options="options"
			:business-areas-filter.sync="businessAreasFilter"
			:approval-states-filter.sync="approvalStatesFilter"
		/>-->

		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0"
		>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
					>
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>

					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto"
					>
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar ..."
								trim
							/>
							<!-- 	<b-link
								href="/reporte_de_consultas.xlsx"
								download
								target="_blank"
							>
								<b-button variant="primary">
									<span class="text-nowrap">DESCARGAR CONSULTAS</span>
								</b-button>
							</b-link>-->
							<!-- <json-excel :data="tableExport()" name="reporte_de_consultas.xls"></json-excel> -->
						</div>
					</b-col>
				</b-row>
			</div>

			<b-table
				ref="refConsultationListTable"
				class="position-relative table-bidders"
				:style="'height: inherit'"
				:items="fetchConsultations"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #table-busy>
					<div class="text-center text-primary my-2">
						<b-spinner class="align-middle mb-1"></b-spinner>
						<div>
							<b>CARGANDO</b>
						</div>
					</div>
				</template>

				<template #head(code)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(createdAt)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(absolved_at)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(code)="data">
					<div class="text-nowrap">{{ data.item.code }}</div>
				</template>

				<template #cell(bidder_name)="data">
					<div class="text-nowrap">{{ data.item.bidder_name }}</div>
				</template>

				<template #cell(question)="data">
					<div
						class="question-nowrap"
					>{{ data.item.question ? data.item.question.slice(0, 50).concat('...') : '' }}</div>
				</template>

				<template #cell(status)="data">
					<div class="text-nowrap">
						<b-badge
							:variant="`light-${data.item.status === true ? 'success' : data.item.status === false ? 'danger' : 'warning' }`"
						>{{ data.item.status === true ? 'ABSUELTA' : data.item.status === false ? 'SIN ABSOLVER' : 'PENDIENTE' }}</b-badge>
					</div>
				</template>

				<template #cell(createdAt)="data">
					<div
						class="text-nowrap"
					>{{ data.item.createdAt ? moment(data.item.createdAt).format('DD-MM-YYYY HH:mm') : '-' }}</div>
				</template>

				<template #cell(absolved_at)="data">
					<div
						lass="text-nowrap"
					>{{ data.item.absolved_at ? moment(data.item.absolved_at).format('DD-MM-YYYY HH:mm') : '-' }}</div>
				</template>

				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL"
					>
						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item
							@click="openConsultationModal(data.item)"
							:disabled="isDisabled"
						>
							<feather-icon :icon="isAssigned && !data.item.status ? 'EditIcon' : 'EyeIcon'" />
							<span
								class="align-middle ml-50"
							>{{ `${ isAssigned && !data.item.status ? 'Absolver' : 'Ver' } consulta`}}</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>

			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span
							class="text-muted"
						>Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalConsultations"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>

		<b-modal
			ref="refConsultationModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			cancel-title="Cerrar"
			centered
			title="ABSOLUCIÓN DE CONSULTA"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
			hide-footer
			size="lg"
		>
			<validation-observer
				#default="{ handleSubmit }"
				ref="refConsultationForm"
			>
				<b-form @submit.prevent="handleSubmit(saveConsultationModal)">
					<b-card
						footer-tag="footer"
						class="mb-0"
					>
						<b-row>
							<b-col
								cols="12"
								class="mb-2"
							>
								<label for="description">
									<strong>Consulta</strong>
								</label>
								<b-form-textarea
									id="description"
									:value="formData.question"
									rows="5"
									disabled
									readonly
								/>
							</b-col>

							<b-col
								cols="12"
								class="mb-2"
							>
								<label>
									<strong>Documentos de consulta</strong>
								</label>
								<b-button-upload
									entity="COMPETITIONS"
									:entity-id="formData.competition_id"
									:is-disabled="true"
									:competition-props="{ isConsultation: true, ownerId: formData.id }"
									:modal-props="{ title: 'DOCUMENTOS DE CONSULTA' }"
									:button-props="{ text: 'VER DOCUMENTOS', block: true, size: 'sm' }"
								/>
							</b-col>

							<b-col
								cols="12"
								class="mb-2"
							>
								<label for="description">
									<strong>Absolución</strong>
								</label>

								<validation-provider
									#default="{ errors }"
									rules="required"
									name="Absolución"
								>
									<b-form-textarea
										id="description"
										v-model="formData.absolution"
										:state="errors.length > 0 ? false : null"
										placeholder="Ingrese respuesta ..."
										rows="5"
										:disabled="isDisabled || !isAssigned"
										:readonly="isDisabled || !isAssigned"
										trim
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<b-col
								cols="12"
								class="mb-2"
							>
								<label>
									<strong>Documentos de Absolución</strong>
								</label>
								<b-button-upload
									entity="COMPETITIONS"
									:entity-id="formData.competition_id"
									:is-disabled="isDisabled || !isAssigned"
									:competition-props="{ isAbsolution: true, ownerId: formData.id }"
									:modal-props="{ title: 'DOCUMENTOS DE ABSOLUCIÓN' }"
									:button-props="{ text: `${ isDisabled || !isAssigned ? 'VER' : 'CARGAR' } DOCUMENTOS`, block: true, size: 'sm' }"
								/>
							</b-col>
						</b-row>

						<template #footer>
							<b-col class="d-flex flex-column flex-sm-row justify-content-sm-end">
								<!-- BUTTON SUBMIT -->
								<b-button-loading
									v-if="isAssigned"
									:disabled="isDisabled"
									text="GUARDAR"
									type="submit"
									class="order-sm-2 order-1 ml-sm-2 ml-0"
									:processing="processing"
								/>

								<b-button
									v-if="!processing"
									v-ripple.400="'rgba(186, 191, 199, 0.15)'"
									type="button"
									variant="outline-secondary"
									class="ml-2"
									@click="closeConsultationModal"
								>CERRAR</b-button>
							</b-col>
						</template>
					</b-card>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import {
	ref,
	onBeforeMount,
	onUnmounted,
	onMounted
} from "@vue/composition-api"
import { Stages, TenderStates } from "@core/utils/data"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required } from "@validations"
import TabConsultationsListFilters from "./TabConsultationsListFilters.vue"
import BButtonUploadFile from "@core/components/b-button-upload-file/BButtonUploadFile.vue"
import BButtonUpload from "@core/components/b-button-upload/BButtonUpload.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import consultationStoreModule from "./consultationStoreModule"
import useTabConsultationsList from "./useTabConsultationsList"
import BCardCountdown from "@core/components/b-card-countdown/BCardCountdown"
import { avatarText } from "@core/utils/filter"
import VueSweetalert2 from "vue-sweetalert2"
import useNotifications from "@notifications"
import JsonExcel from "./download-excel"
import vSelect from "vue-select"
import store from "@/store"
import moment from "moment"
import axios from "@axios"
import Ripple from "vue-ripple-directive"
import Vue from "vue"
import "animate.css"

Vue.use(VueSweetalert2)

export default {
	components: {
		ValidationObserver,
		ValidationProvider,
		TabConsultationsListFilters,
		BButtonUploadFile,
		BButtonUpload,
		BButtonLoading,
		BCardCountdown,
		required,
		vSelect,
		JsonExcel
	},
	data() {
		return {
			moment
		}
	},
	directives: {
		Ripple
	},
	props: {
		tenderId: {
			type: Number,
			required: true
		},
		scheduleData: {
			type: Array,
			required: true
		},
		tenderData: {
			type: Object,
			required: true
		},
		isAssigned: {
			type: Boolean,
			required: true
		}
	},
	setup(props) {
		const { ABSOLUCION_CONSULTA } = Stages
		const { EN_CURSO } = TenderStates

		// USE TOAST
		const { swalNotification } = useNotifications()

		// REFS
		const tenderId = ref(props.tenderId)
		const tenderData = ref(props.tenderData)
		const scheduleData = ref(props.scheduleData)
		const deadline = ref(null)
		const startDate = ref(null)
		const isDisabled = ref(false)
		const stageClosed = ref(false)
		const options = ref({})
		const refConsultationModal = ref(null)
		const processing = ref(false)
		const formData = ref({
			id: null,
			question: null,
			competition_id: null,
			absolution: null,
			status: null
		})

		if (tenderData.value.tender_state_id !== EN_CURSO) {
			stageClosed.value = true
			isDisabled.value = true
		}

		const BIDDER_APP_STORE_MODULE_NAME = "app-consultation"

		// REGISTER MODULE
		if (!store.hasModule(BIDDER_APP_STORE_MODULE_NAME))
			store.registerModule(
				BIDDER_APP_STORE_MODULE_NAME,
				consultationStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(BIDDER_APP_STORE_MODULE_NAME))
				store.unregisterModule(BIDDER_APP_STORE_MODULE_NAME)
		})

		const consultationItems = ref([])

		// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
		onBeforeMount(() => {
			setData()
		})

		const setData = () => {
			const consultation = scheduleData.value.find(
				(item) => parseInt(item.tender_stage_id) === ABSOLUCION_CONSULTA
			)

			deadline.value = consultation.actual_end_date || ""
			startDate.value = consultation.actual_start_date || ""
		}

		const openConsultationModal = (data) => {
			formData.value = {
				id: data.id,
				question: data.question,
				absolution: data.absolution,
				status: data.status,
				competition_id: data.competition_id
			}

			refConsultationModal.value.show()
		}

		const closeConsultationModal = () => refConsultationModal.value.hide()

		const tableExport = () => {
			let array = []

			for (let a = 0; a < consultationItems.value.length; a++) {
				let objBody = {
					"#": a + 1,
					CÓDIGO: consultationItems.value[a]["code"],
					ASUNTO: consultationItems.value[a]["title"],
					CONSULTA: consultationItems.value[a]["description"],
					"FECHA DE CONSULTA": consultationItems.value[a]["createdAt"],
					"ABSOLUCIÓN DE CONSULTA": ""
				}

				array.push(objBody)
			}

			return array
		}

		const saveConsultationModal = () => {
			process.value = true

			const payload = {
				tender_id: tenderId.value,
				absolution: formData.value.absolution
			}

			store
				.dispatch(`app-consultation/updateConsultation`, {
					id: formData.value.id,
					payload
				})
				.then((response) => {
					refetchData()
					swalNotification(response.data.message)
					closeConsultationModal()
				})
				.catch((error) => {
					const message =
						error.response.data.message ||
						"Error al actualizar la informaición."
					swalNotification(message, false)
				})
				.finally(() => {
					process.value = false
				})
		}

		const handleTimeFinished = () => {
			stageClosed.value = true
			isDisabled.value = true
		}
		const handleNotYetStarted = (value) => {
			if (tenderData.value.tender_state_id !== EN_CURSO) {
				stageClosed.value = true
				isDisabled.value = true
			} else {
				isDisabled.value = value
				stageClosed.value = value
			}
		}

		const {
			fetchConsultations,
			tableColumns,
			perPage,
			currentPage,
			totalConsultations,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refConsultationListTable,
			refetchData
		} = useTabConsultationsList(tenderId.value)

		return {
			fetchConsultations,
			tableColumns,
			options,
			perPage,
			currentPage,
			totalConsultations,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refConsultationListTable,
			refetchData,
			formData,
			isDisabled,
			stageClosed,

			// ACTIONS
			avatarText,
			tableExport,
			deadline,
			startDate,
			handleTimeFinished,
			handleNotYetStarted,
			refConsultationModal,
			openConsultationModal,
			closeConsultationModal,
			saveConsultationModal,
			processing
		}
	}
}
</script>

<style lang="scss" scoped>
.media {
	align-items: center;
}

.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>